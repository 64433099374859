import React from 'react'
import {Container, Header} from 'semantic-ui-react'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import * as styles from './styles/pages.module.css'

import IamnetConsent from '../components/iamnetconsent'

const PrivacyPage = ({location}) => {
	return (
		<Layout location={location}>
			<Seo title={"Privacy"} meta={[{name: `robots`, content: `noindex`}]} />
			<Container className={styles.container}>
				<Header className={styles.superHeader}><h1>Privacy Policy</h1></Header>
				<p className={styles.text}>
					Last updated: July 22, 2020
				</p>
				<p className={styles.text}>
					This page informs you of our policies regarding the collection,
					use and disclosure of Personal Information we receive from users of the Site.
					We use your Personal Information only for providing and improving the Site. By using the Site,
					you agree to the collection and use of information in accordance with this policy.
				</p>
				<Header className={styles.header}>Information Collection And Use</Header>
				<p className={styles.text}>
					While using our Site, we may ask you to provide us with certain personally identifiable information
					that can be used to contact or identify you. Personally identifiable information may include,
					but is not limited to your name, contact details and address.  These are primarily used to
					facilitate transactions made using the Site.
				</p>
				<Header className={styles.header}>Log Data</Header>
				<p className={styles.text}>
					We also collect information that your browser sends whenever you visit our Site.
					This Log Data may include information such as your computer's Internet Protocol ("IP") address,
					browser type, browser version, the pages of our Site that you visit, the time and date of your visit,
					the time spent on those pages and other statistics. In addition, we may use third party services
					such as Google Analytics that collect, monitor and analyze this to improve our services.
				</p>
				<Header className={styles.header}>Communications</Header>
				<p className={styles.text}>
					We may use your Personal Information to contact you to verify your identity, process and
					give updates on the orders made through the Site.  We may also contact you to offer new
					products and send marketing or promotional materials and other such information.
				</p>
				<Header className={styles.header}>Cookies</Header>
				<p className={styles.text}>
					Cookies are files with small amount of data, which may include an anonymous unique identifier.
					Cookies are sent to your browser from a web site and stored on your computer's hard drive.
					Like many sites, we use "cookies" to collect information. You can instruct your browser to
					refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept
					cookies, you may not be able to use some portions of our Site.
				</p>
				<Header className={styles.header}>Security</Header>
				<p className={styles.text}>
					The security of your Personal Information is important to us.  We strive to use
					commercially acceptable means to protect your Personal Information.  However, as
					with other similar services, we cannot guarantee its absolute security.
				</p>
				<Header className={styles.header}>Changes To This Privacy Policy</Header>
				<p className={styles.text}>
					This Privacy Policy will remain in effect except with respect to any changes in its
					provisions in the future, which will be in effect immediately after being posted on
					this page. We reserve the right to update or change our Privacy Policy at any time
					and you should check this Privacy Policy periodically. Your continued use of the
					Service after we post any modifications to the Privacy Policy on this page will
					constitute your acknowledgment of the modifications and your consent to abide and be
					bound by the modified Privacy Policy. If we make any material changes to this Privacy Policy,
					we will notify you either through the email address you have provided us, or by placing
					a prominent notice on our website.
				</p>
				<Header className={styles.header}>Contact Us </Header>
				<p className={styles.text}>
					Feel free to contact us if you have any questions about this Privacy Policy.
				</p>
				<br/><br/>
				<div>
					<IamnetConsent location={location} geticon={true} />
				</div>
			</Container>
		</Layout>
	)
}

export default PrivacyPage
